/* Osiot */
.section {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .section-title {
    font-size: 1em;
    width: 100%;
    margin-right: 15px;
  }
  
  .section-title-display {
    font-size: 2em;
    color: #ff69b4;
    flex-grow: 1;
    text-align: left;
  }
  
  .toggle-tasks-btn {
    background-color: #ffb6c1;
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
  }
  
  .toggle-tasks-btn:hover {
    background-color: #ffa6b2;
  }

  .section-progress {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 15px;
  }

  .section-progress .progress-bar {
    min-width: 150px;
    margin: 0 100px 0 0;
  }

  .buttons {
    display: flex;
    align-items: center;
  }

  .buttons button {
    margin: 0 10px;
    white-space: nowrap;
  }
  
  /* Tehtävät */
  .tasks-list {
    margin-top: 20px;
  }