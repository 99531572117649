/* Yleinen asettelu */
.app {
  font-family: 'Nanum Pen Script', cursive;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
}

h1 {
  color: #ffb6c1;
  font-size: 3em;
  margin-bottom: 20px;
  text-shadow: 1px 1px #f08080;
}

input, textarea {
  width: 100%;
  border-radius: 10px;
  padding: 8px 12px;
  border: 1px solid #ffb6c1;
  font-size: 1em;
  resize: none;
  background-color: #fff;
  box-sizing: border-box;
}

input:focus, textarea:focus {
  outline: 1px solid #f08080;
}

/* Buttonit */
.add-section-btn, .edit-title-btn, .edit-task-btn, .save-title-btn, .save-task-btn, .delete-task-btn, .delete-section-btn, .add-task-btn, button {
  background-color: #ffb6c1;
  border: none;
  color: white;
  padding: 8px 16px;
  margin: 10px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.delete-task-btn, .delete-section-btn {
  background-color: #f75a5a;
}

.add-section-btn:hover, .edit-title-btn:hover, .edit-task-btn:hover, .save-title-btn:hover, .save-task-btn:hover, .add-task-btn:hover {
  background-color: #ffa6b2;
}

.delete-task-btn:hover, .delete-section-btn:hover {
  background-color: #ea3030;
}

/* Progress Bar */
.progress-bar {
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 20px;
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
}

.progress-bar-inner {
  background-color: #ffb6c1;
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.overall-progress {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
}

/* Buttonien fontit */
button {
  font-family: 'Nanum Pen Script', cursive;
  font-size: 1.2em;
}

/* Lisää fontit ja yleinen tyyli */
body {
  font-family: 'Nanum Pen Script', cursive;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

/* lomakkeet */
form {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
}

form input + input {
  margin-top: 20px;
}

form button[type=submit] {
  margin: 30px 0 0;
  width: 100%;
  font-size: 1.5rem;
}

.change-form {
  margin-top: 50px;
}

/* notification */
.notification {
  margin-bottom: 20px;
  color: red;
}