/* Tehtävä */
.task {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    background-color: #ffe4e1;
    border-radius: 10px;
    padding: 15px 8px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    align-items: flex-start;
    box-sizing: border-box;
  }
  
  /* Checkboxit */
  .task-checkbox-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 4px;
  }
  
  .task-checkbox {
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
  
  .custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
  
  .custom-checkbox .checkbox-checkmark {
    position: absolute;
    border-radius: 5px;
    border: 2px solid #ffb6c1;
    background-color: #fff;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 3px #f8f9fa inset;
    transition: background-color 0.3s ease, border 0.3s ease;
  }
  
  .custom-checkbox input[type="checkbox"]:checked + .checkbox-checkmark {
    background-color: #ffb6c1;
    border: 2px solid #ff69b4;
  }
  
  .custom-checkbox .checkbox-checkmark::after {
    content: "";
    position: absolute;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    top: 3px;
    left: 4px;
    background: url('https://img.icons8.com/ios-filled/50/ffffff/checkmark.png') center center no-repeat;
    background-size: contain;
    transform: scale(0);
    transition: transform 0.3s ease;
  }
  
  .custom-checkbox input[type="checkbox"]:checked + .checkbox-checkmark::after {
    transform: scale(1);
  }
  
  /* Teksti ja muokkaus */
  .task-text {
    flex-grow: 1;
    font-size: 1.7em;
    line-height: 1.2;
  }

  input.task-text {
    margin-bottom: 10px;
    font-size: 1.2em;
  }

  .show-task-container, .edit-task-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    flex-grow: 1;
  }

  .show-task-container > div, .edit-task-container > div {
    width: 100%;
    text-align: left;
  }
  
  .task-text.completed {
    text-decoration: line-through;
  }
  
  .task .edit-task-btn, .task .edit-notes-btn, .task .save-task-btn, .task .save-notes-btn {
    background-color: #ffb6c1;
    border: none;
    color: white;
    padding: 8px 16px;
    margin: 0;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .edit-task-btn:hover, .edit-notes-btn:hover, .save-task-btn:hover, .save-notes-btn:hover {
    background-color: #ffa6b2;
  }
  
  /* Notes */
  
  .task-notes-input {
    width: 100%;
    height: 60px;
    font-size: 1em;
    resize: none;
  }
  
  .task-notes-display {
    font-size: 1.2em;
    color: #333;
    padding: 0 8px;
  }
  
  .task .delete-task-btn {
    margin: 0 8px 0 15px;
    padding: 8px 14px;
  }

  .add-task-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
  }

  .add-task-form {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-grow: 1;
  }